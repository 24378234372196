import {
  FETCH_POS_PRODUCT,
  FETCH_POS_PRODUCT_SUCCESS
} from '../constants/ActionTypes';

export const fetchPosProducts = (page, sortColumn, sortOrder) => {
  return {
    type: FETCH_POS_PRODUCT,
    page: page,
    sortColumn,
    sortOrder
  };
};

export const fetchPosProductSuccess = (data, options) => {
  return {
    type: FETCH_POS_PRODUCT_SUCCESS,
    payload: data,
    options
  }
};