import {
  FETCH_POS_ORDER,
  FETCH_POS_ORDER_SUCCESS
} from '../constants/ActionTypes';

export const fetchPosOrders = (page) => {
  // console.log('fetchPosOrders actions - page: ', page)
  return {
    type: FETCH_POS_ORDER,
    page: page
  };
};

export const fetchPosOrderSuccess = (data) => {
  return {
    type: FETCH_POS_ORDER_SUCCESS,
    payload: data
  }
};