import {default as appConfig} from "../../config";
import {userSignOut} from 'actions/Auth';
import {store} from '../../MainApp';
const axios = require('axios');

const getRestfulApiRequest = () => {
    let config = {
        baseURL: `${appConfig.API_BASE_URL}`,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
            'X-Requested-With': 'XMLHttpRequest'
        }
    };
    let custom = axios.create(config);
    // Add a request interceptor
    custom.interceptors.request.use(function (config) {
        return config;
    }, function (error) {
        console.log('Restful API Request call - Request error: ', error);
        return Promise.reject(error);
    });

    // Add a response interceptor
    custom.interceptors.response.use(function (response) {
        // Do something with response data
        return response;
    }, function (error) {
        // Do something with response error
        if (error.response) {//wow this is network error (can't connect to server)// need to do something with this
            console.log('Network error : ', error.response);
            if(error.response.status === 401){
                // document.location.href='/signin';
                store.dispatch(userSignOut());
            }
        }
        return Promise.reject(error);
    });
    return custom;
};

export default getRestfulApiRequest();