import appLocaleData from 'react-intl/locale-data/vi';
import viMessages from '../locales/vi_VN.json';

const ViLang = {
  messages: {
    ...viMessages
  },
  locale: 'vi-VN',
  data: appLocaleData
};
export default ViLang;
