
export default (authData) => {
    return {
        "user":{
            "uid":"vUAqPRNWgYdS2DyJNAC1lV5KpJF2",
            "displayName":null,
            "photoURL":null,
            "email":authData.name,
            "roles": authData.roles,
            "brands": authData.brands,
            "emailVerified":false,
            "phoneNumber":null,
            "isAnonymous":false,
            "tenantId":null,
            "providerData":[
                {
                    "uid":"demo@example.com",
                    "displayName":null,
                    "photoURL":null,
                    "email":"demo@example.com",
                    "phoneNumber":null,
                    "providerId":"password"
                }
            ],
            "apiKey":"AIzaSyCYaTKjfam_qMXDnGfcdnBxScEq89VQtLk",
            "appName":"[DEFAULT]",
            "authDomain":"curious-sandbox-196209.firebaseapp.com",
            "stsTokenManager":{
                "apiKey":"AIzaSyCYaTKjfam_qMXDnGfcdnBxScEq89VQtLk",
                "refreshToken":"AEu4IL0moQsSDV3txVk_Q2oJdEeH5SWNAU_IN8ByKjheW7E1BEOHQJf5yHkO4nVN7bLNrrOeOUAfuwyoCjjCUSodUgflKR1LFmuvJ0b_viLqhvtm_PTZSd_djE7c4quY7JJeoSf2MsUSfTWr_P7e5h_5H3eJj0IOdiHo2Ev_rKC7RAlGy2sS1ly1mwqJ8Dhv3w7ShXQYtOuaFPClM3Ox0uhsEBymD_WAOyaxP07TKSmynwO4lLFvlWY",
                "accessToken":"eyJhbGciOiJSUzI1NiIsImtpZCI6IjA0NjUxMTM5ZDg4NzUyYjY0OTM0MjUzNGE2YjRhMDUxMjVkNzhmYmIiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL3NlY3VyZXRva2VuLmdvb2dsZS5jb20vY3VyaW91cy1zYW5kYm94LTE5NjIwOSIsImF1ZCI6ImN1cmlvdXMtc2FuZGJveC0xOTYyMDkiLCJhdXRoX3RpbWUiOjE1NzUzMzY2OTQsInVzZXJfaWQiOiJ2VUFxUFJOV2dZZFMyRHlKTkFDMWxWNUtwSkYyIiwic3ViIjoidlVBcVBSTldnWWRTMkR5Sk5BQzFsVjVLcEpGMiIsImlhdCI6MTU3NTMzNjY5NCwiZXhwIjoxNTc1MzQwMjk0LCJlbWFpbCI6ImRlbW9AZXhhbXBsZS5jb20iLCJlbWFpbF92ZXJpZmllZCI6ZmFsc2UsImZpcmViYXNlIjp7ImlkZW50aXRpZXMiOnsiZW1haWwiOlsiZGVtb0BleGFtcGxlLmNvbSJdfSwic2lnbl9pbl9wcm92aWRlciI6InBhc3N3b3JkIn19.ptW93SPRucfT69Y5fEWkARce4zxsComj2OBy26X6cuPX3B7WWBNrirTkjWJe862xtw92p03rxi2vG6uv3mtJIS6k-Aih9mubNrfamubYMtz_-Q8sLpN5ckx31aiUwxSCU2T9kKSIN9eeIjuopkpGOYC786J1sqYjKtaRpF7YyELzFMh1qAhiUnzILl7-W6ul9GEC5YYTxek7fLNQIua09iJAk2xqpnDrA6rYFu8RCe6cetcR8eQlHai2rwg85PfU_KJ7hcQdK4gD8NuHtlSyHmpUyp8yaBlESNMkcVNIeSWG87sLYbMXTGxrxoOCSRUbZhzw1xvoJckxCWUPwBGWMA",
                "expirationTime":1575340293757
            },
            "redirectEventId":null,
            "lastLoginAt":"1575336694356",
            "createdAt":"1520250117000"
        },
        "credential":null,
        "additionalUserInfo":{
            "providerId":"password",
            "isNewUser":false
        },
        "operationType":"signIn"
    };
}