import {all, call, put, takeEvery} from 'redux-saga/effects';
import {FETCH_POS_ORDER} from "../constants/ActionTypes";
import {fetchPosOrderSuccess} from 'actions/PosOrders';
import {getPosOrders} from 'services/data/PosOrders';


function* fetchPosOrdersRequest(params) {
  try {
    // console.log(`fetchPosOrdersRequest ... `, params);
    const {page} = params;
    const res = yield call(getPosOrders, {page});
    if(res)
    yield put(fetchPosOrderSuccess(res));
  } catch (error) {
    // yield put(showMailMessage(error));
  }
}


export default function* rootSaga() {
  yield all([takeEvery(FETCH_POS_ORDER, fetchPosOrdersRequest)]);
}