import {
  FETCH_POS_ORDER_SUCCESS
} from 'constants/ActionTypes';

const INIT_STATE = {
  list: []
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_POS_ORDER_SUCCESS: {
      return {
        ...state,
        list: action.payload.currentResults,
        currentPage: action.payload.currentPage,
        totalItems: action.payload.maxResults,
        totalPages: action.payload.totalPages
      }
    }

    default:
      return state;
  }
}