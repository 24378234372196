import {all, call, put, takeEvery} from 'redux-saga/effects';
import {FETCH_POS_CUSTOMER} from "../constants/ActionTypes";
import {fetchPosCustomerSuccess} from 'actions/PosCustomers';
import {getPosCustomers} from 'services/data/PosCustomers';


function* fetchPosCustomersRequest(params) {
  try {
    const {page} = params;
    const res = yield call(getPosCustomers, {page});
    if(res)
    yield put(fetchPosCustomerSuccess(res));
  } catch (error) {
    // yield put(showMailMessage(error));
  }
}


export default function* rootSaga() {
  yield all([takeEvery(FETCH_POS_CUSTOMER, fetchPosCustomersRequest)]);
}