import React from 'react';


const WhitelabelItem = ({whitelabel, switchWhitelabel, handleRequestClose}) => {
  const {logoUrl, code, name} = whitelabel;
  return (
    <li className="pointer" onClick={() => {
      handleRequestClose();
      switchWhitelabel(whitelabel);
    }}>
      <div className="d-flex align-items-center">
        {/*<i className={`flag flag-24 flag-${icon}`}/>*/}
        {logoUrl ? <img src={logoUrl} alt={code}/> : code}
        <h4 className="mb-0 ml-2">{name}</h4>
      </div>
    </li>
  );
};

export default WhitelabelItem;
