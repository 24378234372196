import {all} from 'redux-saga/effects';
import mailSagas from './Mail';
import toDoSagas from './Todo';
import contactSagas from './Contact';
import chatSagas from './Chat';
import authSagas from './Auth';
import posOrderSagas from './PosOrders';
import posProductSagas from './PosProducts';
import posCustomerSagas from './PosCustomers';

export default function* rootSaga(getState) {
  yield all([
    posOrderSagas(),
    posProductSagas(),
    posCustomerSagas(),
    mailSagas(),
    toDoSagas(),
    contactSagas(),
    chatSagas(),
    authSagas()
  ]);
}
