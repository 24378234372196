import {default as restfulApiRequest} from "../common/RestfulApiRequest";

export async function getRestfulData({url}) {
    try {
        let res = await restfulApiRequest({
            method: 'GET',
            url: url
        });
        return res.data;
    }catch (e) {
        console.log(e);
        return null;
    }
}
