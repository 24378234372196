import {
  FETCH_POS_CUSTOMER,
  FETCH_POS_CUSTOMER_SUCCESS
} from '../constants/ActionTypes';

export const fetchPosCustomers = (page) => {
  // console.log('fetchPosOrders actions - page: ', page)
  return {
    type: FETCH_POS_CUSTOMER,
    page: page
  };
};

export const fetchPosCustomerSuccess = (data) => {
  return {
    type: FETCH_POS_CUSTOMER_SUCCESS,
    payload: data
  }
};