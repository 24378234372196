import {default as restfulApiRequest} from './common/RestfulApiRequest';
import AuthUserDeserializer from "./deserializer/AuthUserDeserializer";

function signInWithEmailAndPassword(email, password) {
    // console.log(`call signInWithEmailAndPassword`);
    return new Promise(async (resolve, reject) => {
        try{
            let res = await restfulApiRequest({
                method: 'GET',
                url: 'current-user',
                auth: {
                    username: email,
                    password: password
                }
            });

            // console.log(`the signInWithEmailAndPassword - user.... `, res.data);
            if(res.data){
                return resolve(AuthUserDeserializer(res.data));
            }else{
                throw new Error('No authentication data');
            }
        }catch (e) {
            console.log('Error when request signing in with email and password ', e);
            return reject({
                "code": 400,
                "message": "EMAIL_NOT_FOUND",
                "errors": [
                    {
                        "message": "EMAIL_NOT_FOUND",
                        "domain": "global",
                        "reason": "invalid"
                    }
                ]
            });
        }
    });
}


export default {
    signInWithEmailAndPassword
}