import {getRestfulData} from "./Common";

export async function getPosOrders({page}){
    return getRestfulData({
        url: `common/product-order?p=${page}`
    });
}

export async function getPosOrderDetails(orderId) {
    return getRestfulData({
        url: `common/product-order/${orderId}/details`
    })
}