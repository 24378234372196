import React from 'react';

import WhitelabelItem from './WhitelabelItem';
import CustomScrollbars from 'util/CustomScrollbars';

const WhitelabelSwitcher = ({switchWhitelabel, handleRequestClose, whitelabels}) => {
  return (
    <CustomScrollbars className="messages-list language-list scrollbar" style={{height: 230}}>
      <ul className="list-unstyled">
        {whitelabels.map((wl, index) => <WhitelabelItem key={index} whitelabel={wl}
                                                             handleRequestClose={handleRequestClose}
                                                            switchWhitelabel={switchWhitelabel}/>)}
      </ul>
    </CustomScrollbars>
  )
};

export default WhitelabelSwitcher;

