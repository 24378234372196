import {all, call, put, takeLatest} from 'redux-saga/effects';
import {FETCH_POS_PRODUCT} from "../constants/ActionTypes";
import {fetchPosProductSuccess} from 'actions/PosProducts';
import {getPosProducts} from 'services/data/PosProducts';


function* fetchPosProductsRequest(params) {
  try {
    // console.log(`fetchPosOrdersRequest ... `, params);
    const {page, sortColumn, sortOrder} = params;
    const res = yield call(getPosProducts, {page, sortColumn, sortOrder});
    if(res)
    yield put(fetchPosProductSuccess(res, {sortOrder, sortColumn}));
  } catch (error) {
    // yield put(showMailMessage(error));
  }
}


export default function* rootSaga() {
  yield all([takeLatest(FETCH_POS_PRODUCT, fetchPosProductsRequest)]);
}